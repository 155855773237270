import { Stack, useTheme } from "@mui/material";
import { categories } from "../utils/constants";
import { Category } from "@mui/icons-material";

const Sidebar = ({ selectedCategory, setSelectedCategory }) => {
  const theme = useTheme(); // Access the theme object

  return (
    <Stack
      direction="row"
      sx={{
        overflowY: "auto",
        height: {
          sx: 'auto',
          md: '95%'
        },
        flexDirection: {
          md: 'column'
        },
    
      }}
    >
      {categories.map((Category) => (
        <button
          className="category-btn"
          onClick={() => setSelectedCategory(Category.name)}
          style={{
            background: Category.name === selectedCategory ? '#FC1503' : '#191a1a',
            color: 'white',
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis !important',
            textOverflow: 'ellipsis',
          }}
          key={Category.name}
        >
          <span style={{ color: Category.name === selectedCategory ? 'white' : 'red', marginRight: '15px' }}>{Category.icon}</span>
          <span style={{ opacity: Category.name === selectedCategory ? '1' : '0.8' }}>{Category.name}</span>
        </button>
      ))}
    </Stack>
  );
};

export default Sidebar;
